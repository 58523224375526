/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import { Provider } from 'react-redux';
import { store } from './src/redux/store/store';
import ThemeWrapper from './theme-wrapper';

export const wrapRootElement = ({ element }) => (
    <Provider store={store}>
      <ThemeWrapper>
        {element}
      </ThemeWrapper>
    </Provider>
);
