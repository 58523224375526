import React from 'react';
import { connect } from 'react-redux';
// import {Helmet} from "react-helmet";
import { useMediaQuery, CssBaseline } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { green, blue, grey, orange } from '@material-ui/core/colors';
import './layout.css';

const ThemeContainer = (props) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const displayMode = props.ui.get('displayMode');
  const displayModeToUse = displayMode === 'auto' ? (prefersDarkMode ? 'dark' : 'light') : displayMode;
  const theme = createTheme({
    palette: {
      type: displayModeToUse,
      
      primary: {
        main: displayModeToUse === 'dark' ? green[700] : blue[500],
      },
      secondary: {
        main: displayModeToUse === 'dark' ? orange[300] : orange[900],
      },
      
      background: {
        default: displayModeToUse === 'dark' ? grey['A400'] : grey[200],
        paper: displayModeToUse === 'dark' ? grey[800] : grey[300],
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      {/* <Helmet>
        <link rel="stylesheet" href={`./layout.css`} />
      </Helmet> */}
      {props.children}
    </ThemeProvider>
  );
}

export default connect((state) => {
  return {
    ui: state.ui
  };
})(ThemeContainer);