import { ActionType } from 'redux-promise-middleware';

export const actionType = {
  ui: {
    setBusyStatus:                'UI_SETBUSYSTATUS',
    setLanguage:                  'UI_SETLANGUAGE',
    setDisplayMode:               'UI_SETDISPLAYMODE',
    setSiteInfo:                  'UI_SETSITEINFO',
  },
  page: {
    
  },
  session: {
    authPending:                  `SESSION_AUTH_${ActionType.Pending}`,
    authFulfilled:                `SESSION_AUTH_${ActionType.Fulfilled}`,
    authRejected:                 `SESSION_AUTH_${ActionType.Rejected}`,

    authHeartbeat:                `SESSION_AUTH_HEARTBEAT`,
    
    refreshPending:               `SESSION_AUTH_REFRESH_${ActionType.Pending}`,
    refreshFulfilled:             `SESSION_AUTH_REFRESH_${ActionType.Fulfilled}`,
    refreshRejected:              `SESSION_AUTH_REFRESH_${ActionType.Rejected}`,

    logoutPending:                `SESSION_LOGOUT_${ActionType.Pending}`,
    logoutFulfilled:              `SESSION_LOGOUT_${ActionType.Fulfilled}`,
    logoutRejected:               `SESSION_LOGOUT_${ActionType.Rejected}`,

    profileGetPending:            `SESSION_PROFILEGET_${ActionType.Pending}`,
    profileGetFulfilled:          `SESSION_PROFILEGET_${ActionType.Fulfilled}`,
    profileGetRejected:           `SESSION_PROFILEGET_${ActionType.Rejected}`,

    profileUpdatePending:         `SESSION_PROFILEUPDATE_${ActionType.Pending}`,
    profileUpdateFulfilled:       `SESSION_PROFILEUPDATE_${ActionType.Fulfilled}`,
    profileUpdateRejected:        `SESSION_PROFILEUPDATE_${ActionType.Rejected}`
  },

  system: {
    settingGetPending:            `SYSTEM_SETTINGGET_${ActionType.Pending}`,
    settingGetFulfilled:          `SYSTEM_SETTINGGET_${ActionType.Fulfilled}`,
    settingGetRejected:           `SYSTEM_SETTINGGET_${ActionType.Rejected}`,

    settingLoginPersistencySetPending:   `SYSTEM_SETTINGSET_LOGINPERSISTENCY_${ActionType.Pending}`,
    settingLoginPersistencySetFulfilled: `SYSTEM_SETTINGSET_LOGINPERSISTENCY_${ActionType.Fulfilled}`,
    settingLoginPersistencySetRejected:  `SYSTEM_SETTINGSET_LOGINPERSISTENCY_${ActionType.Rejected}`,

    settingLoginSecuritySetPending:      `SYSTEM_SETTINGSET_LOGINSECURITY_${ActionType.Pending}`,
    settingLoginSecuritySetFulfilled:    `SYSTEM_SETTINGSET_LOGINSECURITY_${ActionType.Fulfilled}`,
    settingLoginSecuritySetRejected:     `SYSTEM_SETTINGSET_LOGINSECURITY_${ActionType.Rejected}`,

    settingUserManagementSetPending:     `SYSTEM_SETTINGSET_USERMGT_${ActionType.Pending}`,
    settingUserManagementSetFulfilled:   `SYSTEM_SETTINGSET_USERMGT_${ActionType.Fulfilled}`,
    settingUserManagementSetRejected:    `SYSTEM_SETTINGSET_USERMGT_${ActionType.Rejected}`,

    settingLoggingSetPending:            `SYSTEM_SETTINGSET_LOGGING_${ActionType.Pending}`,
    settingLoggingSetFulfilled:          `SYSTEM_SETTINGSET_LOGGING_${ActionType.Fulfilled}`,
    settingLoggingSetRejected:           `SYSTEM_SETTINGSET_LOGGING_${ActionType.Rejected}`,

    settingCodeSetPending:               `SYSTEM_SETTINGSET_CODE_${ActionType.Pending}`,
    settingCodeSetFulfilled:             `SYSTEM_SETTINGSET_CODE_${ActionType.Fulfilled}`,
    settingCodeSetRejected:              `SYSTEM_SETTINGSET_CODE_${ActionType.Rejected}`,

    settingCodeDeletePending:            `SYSTEM_SETTINGDELETE_CODE_${ActionType.Pending}`,
    settingCodeDeleteFulfilled:          `SYSTEM_SETTINGDELETE_CODE_${ActionType.Fulfilled}`,
    settingCodeDeleteRejected:           `SYSTEM_SETTINGDELETE_CODE_${ActionType.Rejected}`,

    settingRoleSetPending:               `SYSTEM_SETTINGSET_ROLE_${ActionType.Pending}`,
    settingRoleSetFulfilled:             `SYSTEM_SETTINGSET_ROLE_${ActionType.Fulfilled}`,
    settingRoleSetRejected:              `SYSTEM_SETTINGSET_ROLE_${ActionType.Rejected}`,

    settingMenuGetPending:               `SYSTEM_SETTINGGET_MENU_${ActionType.Pending}`,
    settingMenuGetFulfilled:             `SYSTEM_SETTINGGET_MENU_${ActionType.Fulfilled}`,
    settingMenuGetRejected:              `SYSTEM_SETTINGGET_MENU_${ActionType.Rejected}`,

    settingMenuSetPending:               `SYSTEM_SETTINGSET_MENU_${ActionType.Pending}`,
    settingMenuSetFulfilled:             `SYSTEM_SETTINGSET_MENU_${ActionType.Fulfilled}`,
    settingMenuSetRejected:              `SYSTEM_SETTINGSET_MENU_${ActionType.Rejected}`,

    settingMenuDeletePending:            `SYSTEM_SETTINGDELETE_MENU_${ActionType.Pending}`,
    settingMenuDeleteFulfilled:          `SYSTEM_SETTINGDELETE_MENU_${ActionType.Fulfilled}`,
    settingMenuDeleteRejected:           `SYSTEM_SETTINGDELETE_MENU_${ActionType.Rejected}`,

    settingScreenSetPending:             `SYSTEM_SETTINGSET_SCREEN_${ActionType.Pending}`,
    settingScreenSetFulfilled:           `SYSTEM_SETTINGSET_SCREEN_${ActionType.Fulfilled}`,
    settingScreenSetRejected:            `SYSTEM_SETTINGSET_SCREEN_${ActionType.Rejected}`,

    settingScreenDeletePending:          `SYSTEM_SETTINGDELETE_SCREEN_${ActionType.Pending}`,
    settingScreenDeleteFulfilled:        `SYSTEM_SETTINGDELETE_SCREEN_${ActionType.Fulfilled}`,
    settingScreenDeleteRejected:         `SYSTEM_SETTINGDELETE_SCREEN_${ActionType.Rejected}`,
  },

  logging: {
    loggingNew:                   `LOGGING_NEW`,

    loggingSyncPending:           `LOGGING_SYNC_${ActionType.Pending}`,
    loggingSyncFulfilled:         `LOGGING_SYNC_${ActionType.Fulfilled}`,
    loggingSyncRejected:          `LOGGING_SYNC_${ActionType.Rejected}`,
  }
}