import { 
  browserName, fullBrowserVersion, osName, osVersion, getUA
} from 'react-device-detect';

const helper = {
  throwError: function(ctx, statusCode, errorCode, message, props) {
    ctx.status = statusCode
    ctx.body = {
      statusCode: statusCode,
      error: errorCode,
      message: message,
      props: props
    }
  },

  getErrorMsg: function(err, withStackTrace=false) {
    let errMsg = '';

    if(err) {
      if(typeof err === 'string') {
        errMsg = err;
      }

      if(typeof err === 'object') {
        if(err.message)
          errMsg = err.message;

        if(withStackTrace && err.stack) {
          errMsg = errMsg + '\n' + err.stack
        }
      }
    }
    return errMsg;
  },

  getAxiosError: function(res) {
    let errObj = {
      statusCode: 0,
      error: '',
      message: ''
    };
    let isFilled = false;

    if(res && res.response && res.response.status !== 200) {
      if(res.response.data) {
        errObj = res.response.data;
        isFilled = true;
      }

      if(!isFilled && res.response.errors && this.arrayHasItem(res.response.errors)) {
        errObj.statusCode = res.response.status;
        errObj.error = res.response.errors[0].extensions.code;
        errObj.message = res.response.errors[0].message;
        isFilled = true;
      }

      if(!isFilled) {
        errObj.statusCode = res.response.status;
        errObj.error = 'ERROR';
        errObj.message = this.stringHasValue(res.message) ? res.message : res.response.statusText;
      }
    }
    return errObj;
  },

  deepCopy: function(obj) {
    var copy;
  
    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;
  
    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }
  
    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.deepCopy(obj[i]);
        }
        return copy;
    }
  
    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
        }
        return copy;
    }
  
    throw new Error("Unable to copy obj! Its type isn't supported.");
  },

  getStateAsJSObj: function(state, field, defaultValue=null) {
    let stateValue = defaultValue;
    if(state && field && field.length > 0) {
      let fields = typeof field === 'string' ? field.split('.') : field;
      if(fields.length === 1)
        stateValue = state.get(field);    // for single level field
      else
        stateValue = state.getIn(fields); // for multi level field

      if(stateValue && stateValue.toJS)   // Check if toJS available before invoke it
        stateValue = stateValue.toJS();

      if(stateValue === undefined || stateValue === null)
        stateValue = defaultValue;
    }

    return stateValue;
  },

  stringHasValue : function(str) {
    if(!this.isNotNullAndUndefined(str)) {
      return false;
    }
    
    let strCmp = str;
    if(typeof str !== 'string')
      strCmp = str.toString();

    return strCmp !== undefined && strCmp !== null && strCmp.trim().length > 0;
  },

  arrayHasItem : function(arr) {
    return arr && Array.isArray(arr) && arr.length > 0;
  },

  padding : function(input, totalChar, padWith='0', padInFront=true) { 
    var result = input;
    if (totalChar > input.length) 
    { 
      for (let i=0; i < (totalChar - input.length); i++) 
      { 
        if(padInFront)
          result = padWith + result; 
        else
          result = result + padWith; 
      } 
    } 
    return result;
  },

  carefullyGetValue : function(obj, props = [], defaultValue='') {
    let bIsNullorUndefined =  obj === null || obj === undefined;
    let curObj = null;

    if(!bIsNullorUndefined) {
      curObj = obj;
      if(props !== null) {
        for(let idx=0; idx < props.length; idx++) {
          bIsNullorUndefined = curObj[props[idx]] === null || curObj[props[idx]] === undefined;
          curObj =  curObj[props[idx]]; // Set the curObj[props[idx]] to curObj so that it will recursive down the depth of the object

          if(bIsNullorUndefined)
            break;
        }
      }
    }
    
    if(bIsNullorUndefined)
      return defaultValue;
    else
      return curObj;
  },

  isNotNullAndUndefined : function(obj, props = []) {
    let bIsNullorUndefined =  obj === null || obj === undefined;
    let curObj = null;

    if(!bIsNullorUndefined) {
      curObj = obj;
      if(props !== null) {
        for(let idx=0; idx < props.length; idx++) {
          bIsNullorUndefined = curObj[props[idx]] === null || curObj[props[idx]] === undefined;
          curObj =  curObj[props[idx]]; // Set the curObj[props[idx]] to curObj so that it will recursive down the depth of the object

          if(bIsNullorUndefined)
            break;
        }
      }
    }
    
    return !bIsNullorUndefined;
  },

  textToHtml : function(plainText, includeContainer=true, containerTag='p') {
    let htmlText = plainText.replace(/"/g,'&quot;'); // 34 22
    htmlText = htmlText.replace(/&/g,'&amp;'); // 38 26
    htmlText = htmlText.replace(/'/g,'&#39;'); // 39 27
    htmlText = htmlText.replace(/</g,'&lt;'); // 60 3C
    htmlText = htmlText.replace(/>/g,'&gt;'); // 62 3E
    htmlText = htmlText.replace(/\^/g,'&circ;'); // 94 5E
    htmlText = htmlText.replace(/‘/g,'&lsquo;'); // 145 91
    htmlText = htmlText.replace(/’/g,'&rsquo;'); // 146 92
    htmlText = htmlText.replace(/“/g,'&ldquo;'); // 147 93
    htmlText = htmlText.replace(/”/g,'&rdquo;'); // 148 94
    htmlText = htmlText.replace(/•/g,'&bull;'); // 149 95
    htmlText = htmlText.replace(/–/g,'&ndash;'); // 150 96
    htmlText = htmlText.replace(/—/g,'&mdash;'); // 151 97
    htmlText = htmlText.replace(/˜/g,'&tilde;'); // 152 98
    htmlText = htmlText.replace(/™/g,'&trade;'); // 153 99
    htmlText = htmlText.replace(/š/g,'&scaron;'); // 154 9A
    htmlText = htmlText.replace(/›/g,'&rsaquo;'); // 155 9B
    htmlText = htmlText.replace(/œ/g,'&oelig;'); // 156 9C
    htmlText = htmlText.replace(/ž/g,'&#382;'); // 158 9E
    htmlText = htmlText.replace(/Ÿ/g,'&Yuml;'); // 159 9F
    htmlText = htmlText.replace(/ /g,'&nbsp;'); // 160 A0
    htmlText = htmlText.replace(/¡/g,'&iexcl;'); // 161 A1
    htmlText = htmlText.replace(/¢/g,'&cent;'); // 162 A2
    htmlText = htmlText.replace(/£/g,'&pound;'); // 163 A3
    htmlText = htmlText.replace(/¥/g,'&yen;'); // 165 A5
    htmlText = htmlText.replace(/¦/g,'&brvbar;'); // 166 A6
    htmlText = htmlText.replace(/§/g,'&sect;'); // 167 A7
    htmlText = htmlText.replace(/¨/g,'&uml;'); // 168 A8
    htmlText = htmlText.replace(/©/g,'&copy;'); // 169 A9
    htmlText = htmlText.replace(/ª/g,'&ordf;'); // 170 AA
    htmlText = htmlText.replace(/«/g,'&laquo;'); // 171 AB
    htmlText = htmlText.replace(/¬/g,'&not;'); // 172 AC
    htmlText = htmlText.replace(/­/g,'&shy;'); // 173 AD
    htmlText = htmlText.replace(/®/g,'&reg;'); // 174 AE
    htmlText = htmlText.replace(/¯/g,'&macr;'); // 175 AF
    htmlText = htmlText.replace(/°/g,'&deg;'); // 176 B0
    htmlText = htmlText.replace(/±/g,'&plusmn;'); // 177 B1
    htmlText = htmlText.replace(/²/g,'&sup2;'); // 178 B2
    htmlText = htmlText.replace(/³/g,'&sup3;'); // 179 B3
    htmlText = htmlText.replace(/´/g,'&acute;'); // 180 B4
    htmlText = htmlText.replace(/µ/g,'&micro;'); // 181 B5
    htmlText = htmlText.replace(/¶/g,'&para'); // 182 B6
    htmlText = htmlText.replace(/·/g,'&middot;'); // 183 B7
    htmlText = htmlText.replace(/¸/g,'&cedil;'); // 184 B8
    htmlText = htmlText.replace(/¹/g,'&sup1;'); // 185 B9
    htmlText = htmlText.replace(/º/g,'&ordm;'); // 186 BA
    htmlText = htmlText.replace(/»/g,'&raquo;'); // 187 BB
    htmlText = htmlText.replace(/¼/g,'&frac14;'); // 188 BC
    htmlText = htmlText.replace(/½/g,'&frac12;'); // 189 BD
    htmlText = htmlText.replace(/¾/g,'&frac34;'); // 190 BE
    htmlText = htmlText.replace(/¿/g,'&iquest;'); // 191 BF
    htmlText = htmlText.replace(/À/g,'&Agrave;'); // 192 C0
    htmlText = htmlText.replace(/Á/g,'&Aacute;'); // 193 C1
    htmlText = htmlText.replace(/Â/g,'&Acirc;'); // 194 C2
    htmlText = htmlText.replace(/Ã/g,'&Atilde;'); // 195 C3
    htmlText = htmlText.replace(/Ä/g,'&Auml;'); // 196 C4
    htmlText = htmlText.replace(/Å/g,'&Aring;'); // 197 C5
    htmlText = htmlText.replace(/Æ/g,'&AElig;'); // 198 C6
    htmlText = htmlText.replace(/Ç/g,'&Ccedil;'); // 199 C7
    htmlText = htmlText.replace(/È/g,'&Egrave;'); // 200 C8
    htmlText = htmlText.replace(/É/g,'&Eacute;'); // 201 C9
    htmlText = htmlText.replace(/Ê/g,'&Ecirc;'); // 202 CA
    htmlText = htmlText.replace(/Ë/g,'&Euml;'); // 203 CB
    htmlText = htmlText.replace(/Ì/g,'&Igrave;'); // 204 CC
    htmlText = htmlText.replace(/Í/g,'&Iacute;'); // 205 CD
    htmlText = htmlText.replace(/Î/g,'&Icirc;'); // 206 CE
    htmlText = htmlText.replace(/Ï/g,'&Iuml;'); // 207 CF
    htmlText = htmlText.replace(/Ð/g,'&ETH;'); // 208 D0
    htmlText = htmlText.replace(/Ñ/g,'&Ntilde;'); // 209 D1
    htmlText = htmlText.replace(/Ò/g,'&Ograve;'); // 210 D2
    htmlText = htmlText.replace(/Ó/g,'&Oacute;'); // 211 D3
    htmlText = htmlText.replace(/Ô/g,'&Ocirc;'); // 212 D4
    htmlText = htmlText.replace(/Õ/g,'&Otilde;'); // 213 D5
    htmlText = htmlText.replace(/Ö/g,'&Ouml;'); // 214 D6
    htmlText = htmlText.replace(/×/g,'&times;'); // 215 D7
    htmlText = htmlText.replace(/Ø/g,'&Oslash;'); // 216 D8
    htmlText = htmlText.replace(/Ù/g,'&Ugrave;'); // 217 D9
    htmlText = htmlText.replace(/Ú/g,'&Uacute;'); // 218 DA
    htmlText = htmlText.replace(/Û/g,'&Ucirc;'); // 219 DB
    htmlText = htmlText.replace(/Ü/g,'&Uuml;'); // 220 DC
    htmlText = htmlText.replace(/Ý/g,'&Yacute;'); // 221 DD
    htmlText = htmlText.replace(/Þ/g,'&THORN;'); // 222 DE
    htmlText = htmlText.replace(/ß/g,'&szlig;'); // 223 DF
    htmlText = htmlText.replace(/à/g,'&aacute;'); // 224 E0
    htmlText = htmlText.replace(/á/g,'&aacute;'); // 225 E1
    htmlText = htmlText.replace(/â/g,'&acirc;'); // 226 E2
    htmlText = htmlText.replace(/ã/g,'&atilde;'); // 227 E3
    htmlText = htmlText.replace(/ä/g,'&auml;'); // 228 E4
    htmlText = htmlText.replace(/å/g,'&aring;'); // 229 E5
    htmlText = htmlText.replace(/æ/g,'&aelig;'); // 230 E6
    htmlText = htmlText.replace(/ç/g,'&ccedil;'); // 231 E7
    htmlText = htmlText.replace(/è/g,'&egrave;'); // 232 E8
    htmlText = htmlText.replace(/é/g,'&eacute;'); // 233 E9
    htmlText = htmlText.replace(/ê/g,'&ecirc;'); // 234 EA
    htmlText = htmlText.replace(/ë/g,'&euml;'); // 235 EB
    htmlText = htmlText.replace(/ì/g,'&igrave;'); // 236 EC
    htmlText = htmlText.replace(/í/g,'&iacute;'); // 237 ED
    htmlText = htmlText.replace(/î/g,'&icirc;'); // 238 EE
    htmlText = htmlText.replace(/ï/g,'&iuml;'); // 239 EF
    htmlText = htmlText.replace(/ð/g,'&eth;'); // 240 F0
    htmlText = htmlText.replace(/ñ/g,'&ntilde;'); // 241 F1
    htmlText = htmlText.replace(/ò/g,'&ograve;'); // 242 F2
    htmlText = htmlText.replace(/ó/g,'&oacute;'); // 243 F3
    htmlText = htmlText.replace(/ô/g,'&ocirc;'); // 244 F4
    htmlText = htmlText.replace(/õ/g,'&otilde;'); // 245 F5
    htmlText = htmlText.replace(/ö/g,'&ouml;'); // 246 F6
    htmlText = htmlText.replace(/÷/g,'&divide;'); // 247 F7
    htmlText = htmlText.replace(/ø/g,'&oslash;'); // 248 F8
    htmlText = htmlText.replace(/ù/g,'&ugrave;'); // 249 F9
    htmlText = htmlText.replace(/ú/g,'&uacute;'); // 250 FA
    htmlText = htmlText.replace(/û/g,'&ucirc;'); // 251 FB
    htmlText = htmlText.replace(/ü/g,'&uuml;'); // 252 FC
    htmlText = htmlText.replace(/ý/g,'&yacute;'); // 253 FD
    htmlText = htmlText.replace(/þ/g,'&thorn;'); // 254 FE
    htmlText = htmlText.replace(/ÿ/g,'&yuml;'); // 255 FF
    htmlText = htmlText.replace(/\n/g,'<br/>');

    return includeContainer ? `<${containerTag}>${htmlText}</${containerTag}>` : htmlText;
  },

  convertTZ: function(date, tzString='Asia/Singapore') {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
  },

  getUTCDateString: function(date) {
    let dtStr = '';
    if(this.isNotNullAndUndefined(date)) {
      dtStr = date.toISOString();
      dtStr = dtStr.replace('T', ' ').replace('Z', '');
    }
    return dtStr;
  },

  // ttl default to 15min (900sec)
  shouldILoad: function(entity, ttl=900) {
    let shouldLoad = true;

    // If entity is null, then return return shouldLoad = true
    if(this.isNotNullAndUndefined(entity)) {
      // If the entity loading and loaded is both false, then just return the shouldLoad as true (default)
      if(entity.loading) {
        // If the entity is still loading, do not try to load again.
        shouldLoad = false;
      }
      else {
        if(entity.loaded) {
          // Only if the entity is already loaded, then check the loadedOn field.
          if(this.isNotNullAndUndefined(entity.loadedOn)) {
            let now = new Date();
            let diffInMilliSec = now.getTime() - entity.loadedOn.getTime();
            shouldLoad = diffInMilliSec > (ttl * 1000);
          }
        }
      }
    }

    return shouldLoad;
  },

  onFormFieldChanged: function(field, value, frmData, setState) {
    let form = this.isNotNullAndUndefined(frmData) ? this.deepCopy(frmData) : {};
    form[field] = value;
    setState(form);
  },

  validateFormField: function(field, value, formFields, formValidation, setState, t) {
    let formField = formFields.find(item => item.field === field);
    let formFieldValidate = {error: false, touched: false, message: ''};
    let localFormValidation = this.isNotNullAndUndefined(formValidation) ? this.deepCopy(formValidation) : {};
    
    if(this.isNotNullAndUndefined(formField)) {
      formFieldValidate.touched = true;

      // If required, check if have value
      if(formField.required && !this.stringHasValue(value)) {
        // No value, set error to true and set message
        formFieldValidate.error = true;
        formFieldValidate.message = t('error.message.fieldRequired').replace('{0}', t(formField.label));
      }

      // If field have value and regex is not empty, check the regex
      if(this.stringHasValue(formField.regExp) && this.stringHasValue(value)) {
        let regExp = RegExp(formField.regExp);
        if(!regExp.test(value)) {
          formFieldValidate.error = true;
          formFieldValidate.message = t('error.message.fieldInvalid').replace('{0}', t(formField.label));
        }
      }
      
      
      localFormValidation[field] = formFieldValidate;
      setState(localFormValidation);
    }
  },

  validateForm: function(frmData, formFields, formValidation, setState, t, customValidator=null) {
    // let fields = Helper.arrayHasItem(fieldsToValidate) ? formFields.filter(item => fieldsToValidate.includes(item.field)) : formFields;
    let validation = this.deepCopy(formValidation);
    let formIsValid = true;

    formFields.forEach(item => {
      validation[item.field].error = false;
      validation[item.field].message = '';

      // If required, check if have value
      if(item.required && !this.stringHasValue(frmData[item.field])) {
        // No value, set error to true and set message
        validation[item.field].error = true;
        validation[item.field].message = t('error.message.fieldRequired').replace('{0}', t(item.label));
        formIsValid = false;
      }
      
      // If field have value and regex is not empty, check the regex
      if(this.stringHasValue(item.regExp) && this.stringHasValue(frmData[item.field])) {
        let regExp = RegExp(item.regExp);
        if(!regExp.test(frmData[item.field])) {
          validation[item.field].error = true;
          validation[item.field].message = t('error.message.fieldInvalid').replace('{0}', t(item.label));
          formIsValid = false;
        }
      }
    });

    // If all fields is valid, and customValidator is defined, invoke the customValidator.
    if(formIsValid && customValidator) {
      formIsValid = customValidator();
    }

    setState(validation);
    return formIsValid;
  },

  validateFormRef: function(frmDataRef, formFields, formValidation, setState, t, customValidator=null) {
    // let fields = Helper.arrayHasItem(fieldsToValidate) ? formFields.filter(item => fieldsToValidate.includes(item.field)) : formFields;
    let validation = this.deepCopy(formValidation);
    let formIsValid = true;

    formFields.forEach(item => {
      validation[item.field].error = false;
      validation[item.field].message = '';

      // If required, check if have value
      if(item.required && !this.stringHasValue(frmDataRef.current?.elements[item.field]?.value)) {
        // No value, set error to true and set message
        validation[item.field].error = true;
        validation[item.field].message = t('error.message.fieldRequired').replace('{0}', t(item.label));
        formIsValid = false;
      }
      
      // If field have value and regex is not empty, check the regex
      if(this.stringHasValue(item.regExp) && this.stringHasValue(frmDataRef.current?.elements[item.field]?.value)) {
        let regExp = RegExp(item.regExp);
        if(!regExp.test(frmDataRef.current?.elements[item.field]?.value)) {
          validation[item.field].error = true;
          validation[item.field].message = t('error.message.fieldInvalid').replace('{0}', t(item.label));
          formIsValid = false;
        }
      }
    });

    // If all fields is valid, and customValidator is defined, invoke the customValidator.
    if(formIsValid && customValidator) {
      formIsValid = customValidator();
    }

    setState(validation);
    return formIsValid;
  },

  convertRDGOperatorToDirectUsOperator: function(rdgOperator) {
    let optMap = {
      contains: '_contains',
      notcontains: '_ncontains',
      eq: '_eq',
      neq: '_neq',
      empty: '_empty',
      notempty: '_nempty',
      startswith: '_starts_with',
      endswith: '_ends_with',
      gt: '_gt',
      gte: '_gte',
      lt: '_lt',
      lte: '_lte',
      inrange: '_between',
      notinrange: '_nbetween',
      inlist: '_in',
      notinlist: '_nin',
      after: '_gt',
      afteroron: '_gte',
      before: '_lt',
      beforeoron: '_lte'
    }
    
    return optMap[rdgOperator.toLowerCase()];
  },

  convertRDGFilterValueToDirectUsFilterValue: function(value, type) {
    if(this.isNotNullAndUndefined(value)) {
      if(typeof value === 'object') {
        if(type === 'date') {
          let start = this.stringHasValue(value.start) ? this.getUTCDateString((new Date(value.start))) : null;
          let end = this.stringHasValue(value.end) ? this.getUTCDateString((new Date(value.end))) : null;
          return [start, end];
        }
        else
          return [value.start, value.end];
      }
      else {
        if(type === 'date') {
          return this.stringHasValue(value) ? this.getUTCDateString((new Date(value))) : null;
        }
        else
          return value;
      }
    }
    else {
      return value;
    }
  },

  checkRDGFilterValueIsValid: function(value) {
    if(!this.isNotNullAndUndefined(value)) {
      return false;
    }

    if(typeof value === 'object') {
      return this.stringHasValue(value.start) && this.stringHasValue(value.end);
    }
    else {
      return this.stringHasValue(value);
    }
  },

  populateSearchFilter: function(filters) {
    let populatedFilter = null;

    if(this.isNotNullAndUndefined(filters)) {
      populatedFilter = {};
      if(Array.isArray(filters)) {
        filters.forEach(item => {
          if(this.checkRDGFilterValueIsValid(item.value)) {
            let operator = this.convertRDGOperatorToDirectUsOperator(item.operator);
            if(this.isNotNullAndUndefined(operator)) {
              populatedFilter[item.name] = {};
              populatedFilter[item.name][operator] = this.convertRDGFilterValueToDirectUsFilterValue(item.value, item.type);
            }
          }
        });
      }
      else {
        if(this.checkRDGFilterValueIsValid(filters.value)) {
          let operator = this.convertRDGOperatorToDirectUsOperator(filters.operator);
          if(this.isNotNullAndUndefined(operator)) {
            populatedFilter[filters.name] = {};
            populatedFilter[filters.name][operator] = this.convertRDGFilterValueToDirectUsFilterValue(filters.value, filters.type);
          }
        }
      }
    }
    return populatedFilter;
  },

  populateLoggingDetail: function(error, data, type='error') {
    return (
      {
        app_id: process.env.APP_ID,
        type: type,
        message: this.getErrorMsg(error),
        stack_trace: error.stack,
        source: this.carefullyGetValue(window, 'location.href'.split('.'), ''),
        user: null, // will be set in reducer if any
        data: {
          browser: browserName, 
          browserVersion: fullBrowserVersion, 
          os: osName, 
          osVersion: osVersion,
          ua: getUA,
          state: data,
          date_raised: new Date()
        }
      }
    );
  },

  getLogicalPath: function(siteConfig) {
    let pathName = '/';
    if(typeof window !== 'undefined') {
      pathName = window.location.pathname;
      let langs = this.carefullyGetValue(siteConfig, 'allSitePlugin.nodes.0.pluginOptions.languages'.split('.'), []);
      if(this.arrayHasItem(langs)) {
        for(let idx=0; idx<langs.length; idx++){
          if(pathName.includes(`/${langs[idx]}`)){
            pathName = pathName.replace(`/${langs[idx]}`, '');
            break;
          }
        }
      }

      if(pathName[pathName.length - 1] === '/' && pathName.length > 1) {
        pathName = pathName.substring(0, pathName.length - 1);
      }
    }

    return pathName;
  },

  getSupportedLanguages: function(siteConfig) {
    let langs = [];
    if(typeof window !== 'undefined') {
      langs = this.carefullyGetValue(siteConfig, 'allSitePlugin.nodes.0.pluginOptions.languages'.split('.'), []);
    }

    return langs;
  }
}

export default helper;