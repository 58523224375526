import { combineReducers } from 'redux';
import ui from './uiReducer';
import session from './sessionReducer';
import system from './systemReducer';
import logging from './loggingReducer';
//import page from './pageReducer';

export default combineReducers({
  ui,
  session,
  system,
  logging
})