import { actionType } from '../actions/actionType';
import { fromJS } from 'immutable';
import helper from '../../lib/helper';

function initialState() {
  return fromJS(initialStateJS());
}

function initialStateJS() {
  return ({
    auth: {
      data: null,
      loaded: false,
      loadedOn: null,
      loading: false,
      error: {
        hasError: false,
        lastError: ''
      }
    },
    profile: {
      data: null,
      loaded: false,
      loadedOn: null,
      loading: false,
      error: {
        hasError: false,
        lastError: ''
      }
    }
  });
}

export default function reducer(state=initialState(), action) {
  if(typeof reducer.prototype[action.type] === 'function') 
    return reducer.prototype[action.type](state, action);
  else
    return state;
}

reducer.prototype[actionType.session.refreshPending] =
reducer.prototype[actionType.session.authPending] = (state, action) => {
  return state.setIn('auth.loading'.split('.'), true);
}

reducer.prototype[actionType.session.refreshRejected] =
reducer.prototype[actionType.session.authRejected] = (state, action) => {
  return state.setIn('auth.loading'.split('.'), false)
              .setIn('auth.error.hasError'.split('.'), true)
              .setIn('auth.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.session.refreshFulfilled] =
reducer.prototype[actionType.session.authFulfilled] = (state, action) => {
  return state.setIn('auth.loading'.split('.'), false)
              .setIn('auth.loaded'.split('.'), true)
              .setIn('auth.loadedOn'.split('.'), new Date())
              .setIn('auth.error.hasError'.split('.'), false)
              .setIn('auth.error.lastError'.split('.'), null)
              .setIn('auth.data'.split('.'), action.payload);
}

reducer.prototype[actionType.session.authHeartbeat] = (state, action) => {
  let heartBeat = state.getIn('auth.data.heartBeat'.split('.'));
  if(helper.isNotNullAndUndefined(heartBeat)) {
    return state.setIn('auth.data.heartBeat'.split('.'), new Date());
  }
  else {
    return state;
  }
}

reducer.prototype[actionType.session.profileGetPending] =
reducer.prototype[actionType.session.profileUpdatePending] = (state, action) => {
  return state.setIn('profile.loading'.split('.'), true);
}

reducer.prototype[actionType.session.profileGetRejected] =
reducer.prototype[actionType.session.profileUpdateRejected] = (state, action) => {
  return state.setIn('profile.loading'.split('.'), false)
              .setIn('profile.error.hasError'.split('.'), true)
              .setIn('profile.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.session.profileGetFulfilled] =
reducer.prototype[actionType.session.profileUpdateFulfilled] = (state, action) => {
  return state.setIn('profile.loading'.split('.'), false)
              .setIn('profile.loaded'.split('.'), true)
              .setIn('profile.loadedOn'.split('.'), new Date())
              .setIn('profile.error.hasError'.split('.'), false)
              .setIn('profile.error.lastError'.split('.'), null)
              .setIn('profile.data'.split('.'), action.payload);
}

reducer.prototype[actionType.session.logoutFulfilled] = (state, action) => {
  return initialState();
}