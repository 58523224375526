import { actionType } from '../actions/actionType';
import { fromJS } from 'immutable';
import Helper from '../../lib/helper';

function initialState() {
  return fromJS(initialStateJS());
}

function initialStateJS() {
  return ({
    logs: [],
    last_sync_on: null,
    last_sync_status: '',
    login_user: null,
    max_log_cap: 100 // Default to 100. The max log capacity the browser can hold. This is to prevent when the server is down, the client browser keep generating the log. This will overload the server when it is back online.
  });
}

export default function reducer(state=initialState(), action) {
  if(typeof reducer.prototype[action.type] === 'function') 
    return reducer.prototype[action.type](state, action);
  else
    return state;
}

reducer.prototype[actionType.session.authFulfilled] = (state, action) => {
  return state.set('login_user', Helper.carefullyGetValue(action, 'payload.id'.split('.'), null));
}

reducer.prototype[actionType.session.logoutFulfilled] = (state, action) => {
  return state.set('login_user', null);
}

reducer.prototype[actionType.system.settingGetFulfilled] = (state, action) => {
  var logMaxCap = 100;
  if(Helper.arrayHasItem(action.payload?.configs)) {
    const logConfig = action.payload.configs.find(item => item.category === 'system' && item.identifier === 'system.logging');
    logMaxCap = Helper.carefullyGetValue(logConfig, 'value.max_log_cap'.split('.'), 100);
  }
  return state.set('max_log_cap', logMaxCap);
}

reducer.prototype[actionType.logging.loggingNew] = (state, action) => {
  const logMaxCap = state.get('max_log_cap');
  if(state.get('logs').size >= logMaxCap) {
    return state; // return the same state if current logs exist or equal to max_log_cap
  }
  
  // set the user of the payload to the login user id.
  action.payload.user = state.get('login_user');
  const newLogs = state.get('logs').push(action.payload);
  return state.set('logs', newLogs);
}

reducer.prototype[actionType.logging.loggingSyncFulfilled] = (state, action) => {
  const logs = state.get('logs').toJS();
  if(Helper.arrayHasItem(logs)) {
    logs.splice(0, action.payload.syncedCount);
  }
  
  return state.set('logs', fromJS(logs))
              .set('last_sync_on', new Date())
              .set('last_sync_status', 'success');
}

reducer.prototype[actionType.logging.loggingSyncRejected] = (state, action) => {
  return state.set('last_sync_on', new Date())
              .set('last_sync_status', 'failed');
}
