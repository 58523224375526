var Immutable = require('immutable');
var Serialize = require('@redux-devtools/serialize');
var reduxPersist = require('redux-persist');

const StateTransform = (config) => {
  config = config || {};

  var serializer =  Serialize.immutable(Immutable, config.records);

  return reduxPersist.createTransform(serializer.stringify, serializer.parse, config);
};

export default StateTransform;