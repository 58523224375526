exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acceptinvite-js": () => import("./../../../src/pages/acceptinvite.js" /* webpackChunkName: "component---src-pages-acceptinvite-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-pwdreset-js": () => import("./../../../src/pages/pwdreset.js" /* webpackChunkName: "component---src-pages-pwdreset-js" */),
  "component---src-pages-system-setting-js": () => import("./../../../src/pages/system/setting.js" /* webpackChunkName: "component---src-pages-system-setting-js" */),
  "component---src-pages-system-usermgt-js": () => import("./../../../src/pages/system/usermgt.js" /* webpackChunkName: "component---src-pages-system-usermgt-js" */),
  "component---src-pages-tfasetup-js": () => import("./../../../src/pages/tfasetup.js" /* webpackChunkName: "component---src-pages-tfasetup-js" */),
  "component---src-pages-to-be-removed-indexorg-js": () => import("./../../../src/pages/ToBeRemoved/indexorg.js" /* webpackChunkName: "component---src-pages-to-be-removed-indexorg-js" */),
  "component---src-pages-to-be-removed-page-2-js": () => import("./../../../src/pages/ToBeRemoved/page-2.js" /* webpackChunkName: "component---src-pages-to-be-removed-page-2-js" */),
  "component---src-pages-to-be-removed-template-js": () => import("./../../../src/pages/ToBeRemoved/template.js" /* webpackChunkName: "component---src-pages-to-be-removed-template-js" */),
  "component---src-pages-to-be-removed-using-ssr-js": () => import("./../../../src/pages/ToBeRemoved/using-ssr.js" /* webpackChunkName: "component---src-pages-to-be-removed-using-ssr-js" */),
  "component---src-pages-to-be-removed-using-typescript-tsx": () => import("./../../../src/pages/ToBeRemoved/using-typescript.tsx" /* webpackChunkName: "component---src-pages-to-be-removed-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

