import { actionType } from '../actions/actionType';
import { fromJS } from 'immutable';
import Helper from '../../lib/helper';

function initialState() {
  return fromJS(initialStateJS());
}

function initialStateJS() {
  return ({
    setting: {
      data: null,
      loaded: false,
      loadedOn: null,
      loading: false,
      error: {
        hasError: false,
        lastError: ''
      }
    },
    menuMgt: {
      data: null,
      loaded: false,
      loadedOn: null,
      loading: false,
      error: {
        hasError: false,
        lastError: ''
      }
    }
   
  });
}

export default function reducer(state=initialState(), action) {
  if(typeof reducer.prototype[action.type] === 'function') 
    return reducer.prototype[action.type](state, action);
  else
    return state;
}

reducer.prototype[actionType.system.settingGetPending] = (state, action) => {
  return state.setIn('setting.loading'.split('.'), true);
}

reducer.prototype[actionType.system.settingGetRejected] = (state, action) => {
  return state.setIn('setting.loading'.split('.'), false)
              .setIn('setting.error.hasError'.split('.'), true)
              .setIn('setting.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.system.settingGetFulfilled] = (state, action) => {
  return state.setIn('setting.loading'.split('.'), false)
              .setIn('setting.loaded'.split('.'), true)
              .setIn('setting.loadedOn'.split('.'), new Date())
              .setIn('setting.error.hasError'.split('.'), false)
              .setIn('setting.error.lastError'.split('.'), null)
              .setIn('setting.data'.split('.'), action.payload);
}

reducer.prototype[actionType.system.settingLoginPersistencySetFulfilled] = (state, action) => {
  let stateChanged = false;
  const data = state.getIn('setting.data'.split('.'));
  let newData = null;

  if(Helper.isNotNullAndUndefined(data) && Helper.arrayHasItem(data.configs)) {
    newData = Helper.deepCopy(data);
    let loginPersistency = newData.configs.find(item => item.category === 'authentication' && item.identifier === 'login.persistency');
    if(Helper.isNotNullAndUndefined(loginPersistency?.value)){
      delete action.payload.app_id; // Remove the un-use field
      delete action.payload.id; // Remove the record id returned by the api
      Object.keys(action.payload).forEach(item => {
        loginPersistency.value[item] = action.payload[item];
        stateChanged = true;
      })
    }
  }

  if(stateChanged) {
    return state.setIn('setting.loaded'.split('.'), true)
                .setIn('setting.loadedOn'.split('.'), new Date())
                .setIn('setting.data'.split('.'), newData);
  }
  else {
    return state;
  }
}

reducer.prototype[actionType.system.settingLoginSecuritySetFulfilled] = (state, action) => {
  let stateChanged = false;
  const data = state.getIn('setting.data'.split('.'));
  let newData = null;

  if(Helper.isNotNullAndUndefined(data) && Helper.arrayHasItem(data.configs)) {
    newData = Helper.deepCopy(data);
    let loginSecurity = newData.configs.find(item => item.category === 'authentication' && item.identifier === 'login.security');
    if(Helper.isNotNullAndUndefined(loginSecurity?.value)){
      delete action.payload.app_id; // Remove the un-use field
      delete action.payload.id; // Remove the record id returned by the api
      Object.keys(action.payload).forEach(item => {
        loginSecurity.value[item] = action.payload[item];
        stateChanged = true;
      })
    }
  }

  if(stateChanged) {
    return state.setIn('setting.loaded'.split('.'), true)
                .setIn('setting.loadedOn'.split('.'), new Date())
                .setIn('setting.data'.split('.'), newData);
  }
  else {
    return state;
  }
}

reducer.prototype[actionType.system.settingUserManagementSetFulfilled] = (state, action) => {
  let stateChanged = false;
  const data = state.getIn('setting.data'.split('.'));
  let newData = null;

  if(Helper.isNotNullAndUndefined(data) && Helper.arrayHasItem(data.configs)) {
    newData = Helper.deepCopy(data);
    let usermgt = newData.configs.find(item => item.category === 'system' && item.identifier === 'system.usermgt');
    if(Helper.isNotNullAndUndefined(usermgt?.value)){
      delete action.payload.app_id; // Remove the un-use field
      delete action.payload.id; // Remove the record id returned by the api
      Object.keys(action.payload).forEach(item => {
        usermgt.value[item] = action.payload[item];
        stateChanged = true;
      })
    }
  }

  if(stateChanged) {
    return state.setIn('setting.loaded'.split('.'), true)
                .setIn('setting.loadedOn'.split('.'), new Date())
                .setIn('setting.data'.split('.'), newData);
  }
  else {
    return state;
  }
}

reducer.prototype[actionType.system.settingLoggingSetFulfilled] = (state, action) => {
  let stateChanged = false;
  const data = state.getIn('setting.data'.split('.'));
  let newData = null;

  if(Helper.isNotNullAndUndefined(data) && Helper.arrayHasItem(data.configs)) {
    newData = Helper.deepCopy(data);
    let logging = newData.configs.find(item => item.category === 'system' && item.identifier === 'system.logging');
    if(Helper.isNotNullAndUndefined(logging?.value)){
      delete action.payload.app_id; // Remove the un-use field
      delete action.payload.id; // Remove the record id returned by the api
      Object.keys(action.payload).forEach(item => {
        logging.value[item] = action.payload[item];
        stateChanged = true;
      })
    }
  }

  if(stateChanged) {
    return state.setIn('setting.loaded'.split('.'), true)
                .setIn('setting.loadedOn'.split('.'), new Date())
                .setIn('setting.data'.split('.'), newData);
  }
  else {
    return state;
  }
}

reducer.prototype[actionType.system.settingCodeSetFulfilled] = (state, action) => {
  const data = state.getIn('setting.data'.split('.'));
  let newData = null;

  if(Helper.isNotNullAndUndefined(data?.codes) && Helper.isNotNullAndUndefined(action.payload?.code)) {
    newData = Helper.deepCopy(data);
    let codeText = action.payload.code.text;
    if(data.locale !== 'en-US' && Helper.arrayHasItem(action.payload?.code_lang)) {
      let transItem = action.payload.code_lang.find(item => item.languages_code === data.locale);
      if(Helper.isNotNullAndUndefined(transItem)){
        codeText = transItem.text;
      }
    }
    
    let existingCode = newData.codes.find(item => item.id === action.payload.code.id);
    if(Helper.isNotNullAndUndefined(existingCode)) {
      if(action.payload.code.status !== 'published') {
        // If is existing Code and status is not in published, then remove it from the state
        let existingCodeIdx = newData.codes.findIndex(item => item.id === action.payload.code.id);
        if(existingCodeIdx >= 0)
          newData.codes.splice(existingCodeIdx, 1);
      }
      else {
        // Update the existing code state
        existingCode.category = action.payload.code.category;
        existingCode.code = action.payload.code.code;
        existingCode.sort = action.payload.code.sort;
        existingCode.text = codeText;
      }
    }
    else {
      if(action.payload.code.status === 'published') {
        // If is new code, and status is published, then push it into existing code list
        newData.codes.push({
          id: action.payload.code.id,
          category: action.payload.code.category,
          code: action.payload.code.code,
          text: codeText,
          sort: action.payload.code.sort
        });
      }
    }
  }

  return state.setIn('setting.loaded'.split('.'), true)
              .setIn('setting.loadedOn'.split('.'), new Date())
              .setIn('setting.data'.split('.'), newData);
  
}

reducer.prototype[actionType.system.settingCodeDeleteFulfilled] = (state, action) => {
  const data = state.getIn('setting.data'.split('.'));
  let newData = null;

  if(Helper.isNotNullAndUndefined(data?.codes) && Helper.isNotNullAndUndefined(action.payload)) {
    newData = Helper.deepCopy(data);
    
    let existingCodeIdx = newData.codes.findIndex(item => item.id === action.payload);
    if(existingCodeIdx >= 0) {
      newData.codes.splice(existingCodeIdx, 1);
    }
  }

  return state.setIn('setting.loadedOn'.split('.'), new Date())
              .setIn('setting.data'.split('.'), newData);
  
}

reducer.prototype[actionType.system.settingRoleSetFulfilled] = (state, action) => {
  const data = state.getIn('setting.data'.split('.'));
  let newData = null;

  if(Helper.isNotNullAndUndefined(data) && Helper.arrayHasItem(data.roles)) {
    newData = Helper.deepCopy(data);
    let role = newData.roles.find(item => item.id === action.payload.id);
    if(Helper.isNotNullAndUndefined(role)) {
      if(Helper.arrayHasItem(role.value?.name)){
        role.value.name.forEach(name => {
          name.text = Helper.carefullyGetValue(action, `payload.name_${name.lang.replace('-', '_')}`.split('.'), '');
        });
      }

      if(Helper.arrayHasItem(role.value?.desc)){
        role.value.desc.forEach(desc => {
          desc.text = Helper.carefullyGetValue(action, `payload.desc_${desc.lang.replace('-', '_')}`.split('.'), '');
        });
      }

      if(Helper.isNotNullAndUndefined(role.value))
        role.value.home_url = Helper.carefullyGetValue(action, `payload.home_url`.split('.'), '');
    }
  }

  return state.setIn('setting.loaded'.split('.'), true)
              .setIn('setting.loadedOn'.split('.'), new Date())
              .setIn('setting.data'.split('.'), newData);
}

reducer.prototype[actionType.system.settingMenuGetPending] = (state, action) => {
  return state.setIn('menuMgt.loading'.split('.'), true);
}

reducer.prototype[actionType.system.settingMenuGetRejected] = (state, action) => {
  return state.setIn('menuMgt.loading'.split('.'), false)
              .setIn('menuMgt.error.hasError'.split('.'), true)
              .setIn('menuMgt.error.lastError'.split('.'), action.payload);
}

reducer.prototype[actionType.system.settingMenuGetFulfilled] = (state, action) => {
  return state.setIn('menuMgt.loading'.split('.'), false)
              .setIn('menuMgt.loaded'.split('.'), true)
              .setIn('menuMgt.loadedOn'.split('.'), new Date())
              .setIn('menuMgt.error.hasError'.split('.'), false)
              .setIn('menuMgt.error.lastError'.split('.'), null)
              .setIn('menuMgt.data'.split('.'), action.payload);
}

reducer.prototype[actionType.system.settingMenuSetFulfilled] = (state, action) => {
  const settingData = state.getIn('setting.data'.split('.'));
  const menuMgtData = state.getIn('menuMgt.data'.split('.'));
  let newSettingData = null;
  let newMenuMgtData = null;

  // Update the setting menus state
  if(Helper.arrayHasItem(settingData?.menus) && Helper.isNotNullAndUndefined(action.payload)) {
    newSettingData = Helper.deepCopy(settingData);
    let menuName = action.payload.name;
    let menuTooltip = action.payload.tooltip;
    if(settingData.locale !== 'en-US' && Helper.arrayHasItem(action.payload?.translations)) {
      let transItem = action.payload.translations.find(item => item.languages_code === settingData.locale);
      if(Helper.isNotNullAndUndefined(transItem)){
        menuName = transItem.name;
        menuTooltip = transItem.tooltip;
      }
    }
    
    let existingMenuIdx = newSettingData.menus.findIndex(item => item.id === action.payload.id);
    if(existingMenuIdx >= 0) {
      if(action.payload.status !== 'published') {
        // If is existing Menu and status is not in published, then remove it from the state
          newSettingData.menus.splice(existingMenuIdx, 1);
      }
      else {
        // Update the existing menu state
        newSettingData.menus[existingMenuIdx] = {...action.payload};
        newSettingData.menus[existingMenuIdx].name = menuName;
        newSettingData.menus[existingMenuIdx].tooltip = menuTooltip;
        delete newSettingData.menus[existingMenuIdx].translations;
      }
    }
    else {
      if(action.payload.status === 'published') {
        // If is new menu, and status is published, then push it into existing menu list
        const menuToPush = Helper.deepCopy(action.payload);
        menuToPush.name = menuName;
        menuToPush.tooltip = menuTooltip;
        delete menuToPush.translations;

        newSettingData.menus.push(menuToPush);
      }
    }
  }

  if(Helper.arrayHasItem(menuMgtData)) {
    newMenuMgtData = Helper.deepCopy(menuMgtData);
  }
  else {
    newMenuMgtData = [];
  }

  // Update the menu management state
  let existingMenuMgtIdx = newMenuMgtData.findIndex(item => item.id === action.payload.id);
  if(existingMenuMgtIdx >= 0) {
    newMenuMgtData[existingMenuMgtIdx] = {...action.payload};
  }
  else {
    newMenuMgtData.push(action.payload)
  }
  
  return state.setIn('setting.loaded'.split('.'), true)
              .setIn('setting.loadedOn'.split('.'), new Date())
              .setIn('setting.data'.split('.'), newSettingData)
              .setIn('menuMgt.loaded'.split('.'), true)
              .setIn('menuMgt.loadedOn'.split('.'), new Date())
              .setIn('menuMgt.data'.split('.'), newMenuMgtData);
  
}

reducer.prototype[actionType.system.settingMenuDeleteFulfilled] = (state, action) => {
  const settingData = state.getIn('setting.data'.split('.'));
  const menuMgtData = state.getIn('menuMgt.data'.split('.'));
  let newSettingData = Helper.deepCopy(settingData);
  let newMenuMgtData = null;

  // Update the setting menus state
  if(Helper.arrayHasItem(settingData?.menus) && Helper.stringHasValue(action.payload)) {
    let existingMenuIdx = newSettingData.menus.findIndex(item => item.id === action.payload);
    if(existingMenuIdx >= 0) {
      // Remove it from the state
      newSettingData.menus.splice(existingMenuIdx, 1);
    }
  }

  if(Helper.arrayHasItem(menuMgtData)) {
    newMenuMgtData = Helper.deepCopy(menuMgtData);
  }
  else {
    newMenuMgtData = [];
  }

  // Update the menu management state
  let existingMenuMgtIdx = newMenuMgtData.findIndex(item => item.id === action.payload);
  if(existingMenuMgtIdx >= 0) {
    newMenuMgtData.splice(existingMenuMgtIdx, 1);
  }
  
  return state.setIn('setting.loaded'.split('.'), true)
              .setIn('setting.loadedOn'.split('.'), new Date())
              .setIn('setting.data'.split('.'), newSettingData)
              .setIn('menuMgt.loaded'.split('.'), true)
              .setIn('menuMgt.loadedOn'.split('.'), new Date())
              .setIn('menuMgt.data'.split('.'), newMenuMgtData);
  
}

reducer.prototype[actionType.system.settingScreenSetFulfilled] = (state, action) => {
  const settingData = state.getIn('setting.data'.split('.'));
  let newSettingData = null;
  
  // Update the setting screens state
  if(Helper.arrayHasItem(settingData?.screens) && Helper.isNotNullAndUndefined(action.payload)) {
    newSettingData = Helper.deepCopy(settingData);
    let title = action.payload.title;
    let description = action.payload.description;
    if(settingData.locale !== 'en-US' && Helper.arrayHasItem(action.payload?.translations)) {
      let transItem = action.payload.translations.find(item => item.languages_code === settingData.locale);
      if(Helper.isNotNullAndUndefined(transItem)){
        title = transItem.title;
        description = transItem.description;
      }
    }
    
    let existingScreenIdx = newSettingData.screens.findIndex(item => item.id === action.payload.id);
    if(existingScreenIdx >= 0) {
      if(action.payload.status !== 'published') {
        // If is existing Menu and status is not in published, then remove it from the state
          newSettingData.screens.splice(existingScreenIdx, 1);
      }
      else {
        // Update the existing menu state
        newSettingData.screens[existingScreenIdx] = {...action.payload};
        newSettingData.screens[existingScreenIdx].title = title;
        newSettingData.screens[existingScreenIdx].description = description;
        delete newSettingData.screens[existingScreenIdx].translations;
      }
    }
    else {
      if(action.payload.status === 'published') {
        // If is new menu, and status is published, then push it into existing menu list
        const screenToPush = Helper.deepCopy(action.payload);
        screenToPush.title = title;
        screenToPush.description = description;
        delete screenToPush.translations;

        newSettingData.screens.push(screenToPush);
      }
    }
  }

  return state.setIn('setting.loaded'.split('.'), true)
              .setIn('setting.loadedOn'.split('.'), new Date())
              .setIn('setting.data'.split('.'), newSettingData);
  
}

reducer.prototype[actionType.system.settingScreenDeleteFulfilled] = (state, action) => {
  const settingData = state.getIn('setting.data'.split('.'));
  let newSettingData = Helper.deepCopy(settingData);
  
  // Update the setting screens state
  if(Helper.arrayHasItem(settingData?.screens) && Helper.stringHasValue(action.payload)) {
    let existingScreenIdx = newSettingData.screens.findIndex(item => item.id === action.payload);
    if(existingScreenIdx >= 0) {
      // Remove it from the state
      newSettingData.screens.splice(existingScreenIdx, 1);
    }
  }

  return state.setIn('setting.loaded'.split('.'), true)
              .setIn('setting.loadedOn'.split('.'), new Date())
              .setIn('setting.data'.split('.'), newSettingData);
}